export default {
  "completePassword": {
    "button": {
      "submit": "发送"
    },
    "header": "选择新密码",
    "placeholder": {
      "confirmPassword": "确认密码",
      "password": "密码"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "所有结果",
      "myActions": "我的行动",
      "ourCompanyResults": "我们的结果"
    },
    "card": {
      "body":
        "在此您可以找到您选择执行的个人行动。 您还可以查看您的结果以及相对整个组织的表现情况。",
      "title": "行动和结果"
    },
    "greetings": {
      "day": "嗨，{username}！",
      "evening": "晚上好，{username}！",
      "morning": "早上好，{username}！",
      "night": "晚安，{username}！",
      "simple": "您好！"
    },
    "hello": "您好，{username}！",
    "integrations": {
      "title": "集成"
    },
    "introHeadline": "{productTitle} 就任于 {organizationName}",
    "settings": {
      "ar": "阿拉伯语",
      "body": "在此您可以更改账户设置。",
      "da": "丹麦语",
      "de": "德语",
      "en": "英语",
      "false": "挪威语",
      "fr": "法语",
      "logout": {
        "button": {
          "title": "退出"
        },
        "confirmation": {
          "areyousure":
            "如果退出，您需要申请一个新的一次性验证码才能重新登录。",
          "cancel": "取消",
          "false": "取消",
          "no": "取消",
          "title": "您想要退出吗？",
          "true": "退出",
          "yes": "退出"
        },
        "loggedin": "您以 {username} 身份登录。"
      },
      "no": "挪威语",
      "sv": "瑞典语",
      "title": "设置"
    }
  },
  "error": {
    "CodeMismatchException": "您输入的一次性验证码无效。 请仔细检查并重试。",
    "couldNotLoadResults": "无法加载结果，请重试。",
    "couldNotLoadSurveys": "无法加载调查，请重试。",
    "emailAtLeast3Letter": "电子邮件地址必须包含至少 3 个字母",
    "emptyEmail": "输入您的电子邮件地址",
    "emptyOrganization": "输入您的组织名称",
    "emptyPassword": "输入密码",
    "EternalLoopError": "您的账号似乎无效。 请联系 Puls 支持人员。",
    "ExpiredCodeException":
      "您输入的一次性验证码已经过期，出于安全原因不能再使用。",
    "InvalidParameterException": "缺失必要的参数会话。",
    "InvalidPasswordException": "请选择更安全的密码。",
    "LimitExceededException": "太多次失败尝试。 您必须等待片刻才能重试。",
    "NotAuthorizedException": "目前无法使用您的用户账户登录",
    "notMatchPassword": "密码不相符！",
    "OrganizationNotFoundException": "我们找不到使用该名称的组织，请重试。",
    "UserNotFoundException":
      "我们找不到 {username}。 尝试再次点击邀请电子邮件中的链接！"
  },
  "forgotten": {
    "backToLogin": "取消",
    "helptext":
      "在此您可以重置密码。 您将收到一封发送到上述电子邮箱的电子邮件，其中包含一个验证码，供您在下一步中予以输入。",
    "helptext-quickstart":
      "我们刚才给您发送了一个临时验证码到 {email}。 请在下方输入它，并选择一个密码。",
    "helptext2": "选择一个安全密码。",
    "input": {
      "code": "在电子邮件中找到的验证码",
      "organization": "组织",
      "password": "新密码",
      "username": "电子邮件地址"
    },
    "requestcode": {
      "submit": "继续"
    },
    "setNewPassword": {
      "submit": "开始！"
    },
    "wait": "请稍等……"
  },
  "general": {
    "defaultProductTitle": "Puls⁺"
  },
  "help": {
    "card": {
      "body": "有什么需要我们帮助吗？ 只需点击下方按钮即可前往帮助中心。",
      "title": "帮助和支持"
    },
    "description": "别担心，我们为您提供鼎力支持。",
    "title": "帮助"
  },
  "ie": {
    "detailedText":
      "Puls 平台目前可以使用 Internet Explorer，但它是一种过时的网络浏览器，不符合最新的网络标准。 因此我们建议您使用以下一款浏览器。 您也可以使用手机。",
    "detailedTitle": "关于 Internet Explorer 浏览器",
    "readMore": "阅读全文",
    "warning":
      "您在使用 Internet Explorer 浏览器。 我们建议您改用其他网络浏览器以获取更佳体验。"
  },
  "integrations": {
    "addIntegrationSuccess": "成功添加集成！",
    "allIntegrations": "所有集成",
    "apps": {
      "sftp": {
        "connectionInfo": "将上传至 SFTP 服务器上的 {dir}。",
        "connectionTitle": "SFTP 连接",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "连接到 {teamName} 频道 {channel}",
        "connectionTitle": "Slack 到 {appDetail}",
        "title": "Slack"
      }
    },
    "back": "返回",
    "buttons": {
      "deleteIntegration": {
        "help": "这将从 Puls 移除 {type}。"
      }
    },
    "connectedSince": "自 {date} 起已连接",
    "empty": {
      "description":
        "通过添加集成，您可以将 Puls 与自身服务相关联。 选择下方按钮以连接到一项服务。"
    },
    "errors": {
      "invalidIncomingIntegration": "输入数据对该集成无效！ 无法继续。"
    },
    "events": {
      "surveyCompleted": {
        "description":
          "当一项调查完成时，{groupTitle} 的结果会自动发送到集成。",
        "title": "调查已完成"
      },
      "title": "选择应当联系集成时的事件"
    },
    "groups": {
      "title": "选择分组以连接到 {appName}"
    },
    "integrationTo": "与 {appName} 集成",
    "nothingSelected": "选择……",
    "setupIntegration": {
      "saveButtonTitle": "保存",
      "title": "设置与 {appName} 集成"
    },
    "title": "集成"
  },
  "loggedOut": {
    "loginButton": {
      "title": "重新登录"
    },
    "title": "您现在已退出登录！"
  },
  "login": {
    "button": {
      "forgotPassword": "登录",
      "submit": "登录"
    },
    "captchaLegal": {
      "disclaimer":
        "本网站受 reCAPTCHA 保护并适用 Google {toc} 和 {privacyPolicy}。",
      "policy": "隐私政策",
      "toc": "服务条款"
    },
    "header": "登录",
    "placeholder": {
      "email": "电子邮件地址",
      "organization": "组织",
      "password": "密码"
    }
  },
  "navbar": {
    "welcome": "欢迎"
  },
  "notFound": {
    "alert": "此页面不存在。 请返回原处",
    "backButton": "概览",
    "title": "未找到"
  },
  "results": {
    "participants": "{participants} 名参与者",
    "surveys": {
      "completed": {
        "latest": "最近完成的调查",
        "title": "已完成的调查结果"
      },
      "emptyResult": "很快您就能够在此查看结果！",
      "published": {
        "title": "查看结果"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info": "这是必填项，目的是根据不同年龄组收集统计数据。",
        "label": "生日"
      },
      "birth_date": {
        "info": "此为必填项，目的是根据不同年龄组收集统计数据。",
        "label": "生日"
      },
      "custom:employed_since": {
        "info": "您受雇的日期。 不必是确切日期。 如果您未受雇，则不必选择。",
        "label": "受雇日期"
      },
      "employment_date": {
        "info": "您受雇的日期。 不必是确切日期。 如果您未受雇，则不必选择。",
        "label": "受雇日期"
      },
      "family_name": {
        "label": "姓氏"
      },
      "gender": {
        "other": "其他",
        "zero": "其他",
        "female": "女",
        "info":
          "我们询问性别是为了将其纳入统计数据中。 如果您不想说，可以不选。",
        "label": "选择性别：",
        "male": "男",
        "wontsay": "不想说"
      },
      "given_name": {
        "label": "名字"
      },
      "locale": {
        "ar": "阿拉伯语",
        "da": "丹麦语",
        "de": "德语",
        "dk": "丹麦语",
        "en": "英语",
        "es": "西班牙语",
        "false": "挪威语",
        "fi": "芬兰语",
        "fr": "法语",
        "hr": "克罗地亚语",
        "info": "选择您希望使用的语言。 这以后可以更改。",
        "it": "意大利语",
        "label": "选择语言：",
        "ms": "马来语",
        "nl": "荷兰语",
        "no": "挪威语",
        "pl": "波兰语",
        "ru": "俄语",
        "so": "索马里语",
        "sr": "塞尔维亚语",
        "sv": "瑞典语",
        "uk": "乌克兰语"
      }
    },
    "button": {
      "submit": "保存",
      "tryAgain": "重试"
    },
    "error": {
      "badPassword": "请选择更安全的密码。",
      "clickLinkAgain":
        "请再次点击欢迎电子邮件中的链接。 如果问题仍继续，请联系 support@puls-solutions.com",
      "invalid": "该链接已失效，请改为点击下方：",
      "noPassword": "您必须选择一个密码。",
      "passwordsMustMatch": "密码必须相符。",
      "sessionError": "我们遇到了问题！",
      "SSONotFound": "该组织没有 SSO 配置。",
      "unexpected": "发生意外问题"
    },
    "header": "立即体验 Puls！",
    "introText":
      "您好，{name}！ 很高兴邀请您体验 Puls，这是一个致力于提高员工敬业度的平台。 填写下方表格即可激活您的账户。 ",
    "introTextVolontary": "只需要填写密码。",
    "login": "继续",
    "placeholder": {
      "confirmPassword": "确认密码",
      "newPassword": "选择密码"
    }
  },
  "sso": {
    "button": {
      "continue": "继续",
      "login": "SSO 登录",
      "signout": "退出"
    },
    "error": {
      "generic": "登录时发生问题：{error}"
    },
    "noUserAccountFound": "没有发现该组织的用户账户"
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "继续"
      },
      "requestCode": {
        "title": "获取一次性验证码"
      },
      "submitCode": {
        "title": "登录"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "我们首先希望确定您是正确的那个人！ 接下来会给您发送一个一次性验证码。 在下一步中用它以 {productTitle} 身份登录 {organizationName}。",
        "help":
          "您刚才申请了一个验证码。 务必将该验证码输入您刚才申请时所打开的窗口。 如果您在此继续操作，则会给您发送一个新的验证码。"
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "一次性验证码"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "电子邮件",
          "SMS": "短信"
        },
        "description":
          "已通过 {deliveryMedium} 给您发送了一个一次性验证码。 不要关闭窗口，稍后回来在下方输入该验证码：",
        "title": "欢迎来到 {productTitle}！"
      },
      "help": {
        "noCodeDescriptionEmail":
          "该验证码已通过电子邮件发送给您，电子邮件地址如下：'{destination}'。 发件人是 \"Puls\"。 验证码需要一两分钟才能送达。 如果您找不到它，请检查您的垃圾邮箱。 请勿关闭此窗口并在上方输入该验证码。",
        "noCodeDescriptionSMS":
          "该验证码已通过短信发送至您的手机，手机号码如下：'{destination}'。 验证码需要一两分钟才能送达。 如果您不认识该手机号，请联系您的管理员。",
        "noCodeLinkTitle": "没有收到验证码？",
        "noCodeStartOverLinkTitle": "重新开始"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "电子邮件地址"
        }
      },
      "header": {
        "description": "输入您在 {organizationName} 的电子邮件地址："
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "组织名称"
        }
      },
      "header": {
        "description": "首先输入您所在的组织名称。",
        "invalid": {
          "spaces": "不要使用空格。",
          "specialchars": "仅使用字母。",
          "uppercase": "仅使用小写字母。"
        }
      }
    }
  },
  "surveys": {
    "description": "这些调查目前正在进行：",
    "descriptionOneSurveyOnly": "选择下方调查以立即开始！",
    "descriptionOneSurveyOnlyUserDone":
      "调查仍在进行中。 选中它以查看您已选择的行动。",
    "ended": "已结束",
    "youreDone": "您已完成"
  },
  "surveysToFillIn": {
    "description": "这些调查正在进行中：",
    "descriptionOneSurveyOnly": "选择下方调查并立即开始！",
    "descriptionOneSurveyOnlyUserDone":
      "调查仍在进行中。 选中它以查看您选择的行动。",
    "emptyResult":
      "目前没有调查等您填写。 您可以在下方查看您的行动/评论或结果。",
    "ended": "已结束",
    "endsIn": "结束",
    "retry": "重试",
    "title": "当前调查|您有一个调查要填写|有 {count} 个调查等您填写",
    "youreDone": "您已完成"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info": "这是必填项，目的是根据不同年龄组收集统计数据。",
        "label": "生日"
      },
      "birth_date": {
        "info": "这是必填项，目的是根据不同年龄组收集统计数据。",
        "label": "生日"
      },
      "custom:employed_since": {
        "info": "您受雇的日期。 不必是确切日期。 如果您没有受雇，则不必填写。",
        "label": "受雇始自"
      },
      "employment_date": {
        "info": "您受雇的日期。 不必是确切日期。 如果您没有受雇，则不必填写。",
        "label": "受雇始自"
      },
      "family_name": {
        "label": "名字"
      },
      "gender": {
        "other": "其他",
        "zero": "其他",
        "female": "女性",
        "info":
          "我们询问性别是为了将其纳入统计数据中。 如果您不想说，可以不选。",
        "label": "选择性别：",
        "male": "男性",
        "wontsay": "不想说"
      },
      "given_name": {
        "label": "名字"
      },
      "locale": {
        "ar": "阿拉伯语",
        "da": "丹麦语",
        "de": "德语",
        "en": "英语",
        "es": "西班牙语",
        "false": "挪威语",
        "fi": "芬兰语",
        "fr": "法语",
        "hr": "克罗地亚语",
        "info": "选择您希望使用的语言。",
        "it": "意大利语",
        "label": "选择语言：",
        "ms": "马来语",
        "nl": "荷兰语",
        "no": "挪威语",
        "pl": "波兰语",
        "ru": "俄语",
        "so": "索马里语",
        "sr": "塞尔维亚语",
        "sv": "瑞典语",
        "uk": "乌克兰语",
        "zh": "中文"
      }
    },
    "button": {
      "cancel": "取消",
      "skip": "返回",
      "submit": "保存",
      "tryAgain": "重试"
    },
    "error": {
      "badPassword": "请选择一个更安全的密码。",
      "clickLinkAgain":
        "请再次点击欢迎电子邮件中的链接。 如果问题仍存在，请联系 support@puls-solutions.com",
      "invalid": "该链接已失效，请点击下方：",
      "noPassword": "您必须选择一个密码。",
      "passwordsMustMatch": "密码必须相符。",
      "sessionError": "我们遇到了问题！",
      "SSONotFound": "该组织没有 SSO 配置。",
      "unexpected": "发生了意外问题"
    },
    "header": "用户设置",
    "introText": "请检查您的用户资料是否正确。",
    "introTextNoAttributes": "目前这里没有需要更改的设置。",
    "introTextVolontary": "下方信息为自愿填写。",
    "login": "继续"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "取消",
      "done": "确定",
      "send": "匿名发送"
    },
    "description":
      "我们让您能够以匿名方式举报您在 {organizationName} 看到的违法犯罪行为。",
    "detailedDescription":
      "以下是具体操作方式。 您在下方文本框中输入您的举报信。 在您发送举报信后，一份电子邮件会被发送至您所在组织中负有相关权责的预定收件人。 收件人绝对看不到消息是您发送的，只知道它来自 Puls 平台。  如果您希望有人来联系您，务必在您的消息中包含相关联系信息。 如果您希望保持匿名身份，可以使用可丢弃的（临时性）电子邮件地址或手机 SIM 卡。",
    "doneText":
      "感谢您的举报。 我们会严肃对待。 如果您已经提供了联系信息，我们会尽快给您回复。",
    "linkTitle": "在此阅读全文",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description": "抱歉。 您的组织未启用吹哨人功能。",
      "title": "不可用"
    },
    "placeholder":
      "在此输入您的报告。 请尽可能提供详细具体的信息，并告知您是否希望有人联系您以及联系方式。",
    "title": "发送匿名报告"
  }
}
