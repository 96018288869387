export default {
  "completePassword": {
    "button": {
      "submit": "Wyślij"
    },
    "header": "Wybierz nowe hasło",
    "placeholder": {
      "confirmPassword": "Potwierdź hasło",
      "password": "Hasło"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "Wszystkie wyniki",
      "myActions": "MOJE DZIAŁANIA",
      "ourCompanyResults": "NASZE WYNIKI"
    },
    "card": {
      "body":
        "Tutaj możesz znaleźć swoje własne działania, które wybrałeś. Możesz także wejść i zobaczyć, jak twoje wyniki wypadają na tle innych w twojej grupie lub organizacji.",
      "title": "Ćwiczenia i wyniki"
    },
    "hello": "Witaj {username}!",
    "introHeadline": "WITAJ W PULS W {organizationName}!",
    "settings": {
      "body":
        "Tutaj możesz zmienić język, a także inne ustawienia twojego konta.",
      "da": "Duński",
      "de": "Niemiecki",
      "en": "Angielski",
      "false": "Norweski",
      "fr": "Francuski",
      "logout": {
        "button": {
          "title": "Wyloguj się"
        },
        "confirmation": {
          "areyousure":
            "Jeśli się wylogujesz, musisz zażądać nowego jednorazowego kodu, aby zalogować się ponownie.",
          "cancel": "Anuluj",
          "false": "Anuluj",
          "no": "Anuluj",
          "title": "Czy chcesz się wylogować?",
          "true": "Wyloguj się",
          "yes": "Wyloguj się"
        },
        "loggedin": "Zostałeś zalogowany jako {username}."
      },
      "no": "Norweski",
      "sv": "Szwedzki",
      "title": "Ustawienia"
    }
  },
  "error": {
    "CodeMismatchException":
      "Podany kod jednorazowy jest nieprawidłowy. Prosimy sprawdzić poprawność podanego kodu i spróbować ponownie.",
    "couldNotLoadResults":
      "Nie mogliśmy załadować wyników, proszę spróbować ponownie.",
    "couldNotLoadSurveys":
      "Nie mogliśmy załadować ankiet, proszę spróbować ponownie.",
    "emailAtLeast3Letter": "Adres e-mail musi zawierać przynajmniej 3 litery.",
    "emptyEmail": "Wpisz swój adres e-mail.",
    "emptyOrganization": "Wpisz nazwę swoje organizacji.",
    "emptyPassword": "Wpisz hasło.",
    "ExpiredCodeException":
      "Podany kod jednorazowy jest zbyt stary i ze względów bezpieczeństwa nie można go wykorzystać.",
    "InvalidParameterException": "Brakuje wymaganego parametru Sesja.",
    "InvalidPasswordException": "Proszę wybrać bezpieczniejsze hasło.",
    "LimitExceededException":
      "Zbyt wiele nieudanych prób. Musisz chwilę poczekać, żeby zalogować się ponownie.",
    "NotAuthorizedException":
      "W tej chwili zalogowanie przy pomocy tego konta użytkownika jest niemożliwe.",
    "notMatchPassword": "Hasła nie pasują!",
    "OrganizationNotFoundException":
      "Nie mogliśmy znaleźć organizacji z tą nazwą, spróbuj ponownie.",
    "UserNotFoundException":
      "Nie mogliśmy znaleźć konta z nazwą użytkownika {username}."
  },
  "forgotten": {
    "backToLogin": "Anuluj",
    "helptext":
      "Tutaj możesz zresetować swoje hasło. Otrzymasz e-mail na powyższy adres e-mailowy z kodem, który będzie trzeba wpisać w następnym kroku.",
    "helptext-quickstart":
      "Właśnie wysłaliśmy Ci tymczasowy kod na adres {email}. Wpisz go poniżej i wybierz hasło.",
    "helptext2": "Wybierz bezpieczniejsze hasło.",
    "input": {
      "code": "Kod znaleziony w e-mailu",
      "organization": "Organizacja",
      "password": "Nowe hasło",
      "username": "Adres e-mail"
    },
    "requestcode": {
      "submit": "Kontynuuj"
    },
    "setNewPassword": {
      "submit": "Start!"
    },
    "wait": "Prosimy czekać..."
  },
  "general": {
    "defaultProductTitle": "Puls⁺"
  },
  "help": {
    "card": {
      "body":
        "Czy możemy ci w czymś pomóc? Jeśli tak, kliknij przycisk poniżej, aby przejść do centrum pomocy.",
      "title": "Pomoc i wsparcie"
    },
    "description": "Nie martw się, jesteśmy z tobą.",
    "title": "POMOC"
  },
  "ie": {
    "detailedText":
      "Platforma Puls może być obsługiwana w Internet Explorerze, ale jest to stara przeglądarka internetowa, która nie spełnia współczesnych standardów internetowych. Z tego względu rekomendujemy korzystanie z jednej z przeglądarek wymienionych poniżej. Możesz też używać urządzenia mobilnego.",
    "detailedTitle": "O przeglądarce Internet Explorer",
    "readMore": "P",
    "warning":
      "Używasz przeglądarki Internet Explorer. Dla najlepszej funkcjonalności polecamy zmianę przeglądarki."
  },
  "integrations": {
    "allIntegrations": "Wszystkie integracje",
    "apps": {
      "sftp": {
        "connectionInfo": "Zostanie załadowany do {dir} na serwerze SFTP.",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "Podłączony do kanału {channel} {teamName}",
        "title": "Slack"
      }
    },
    "connectedSince": "Połączone od {date}",
    "empty": {
      "description":
        "Dodając integrację, możesz sprawić, że Puls będzie łączyć się z Twoim własnym serwisem. Wybierz przycisk poniżej, by połączyć się z serwisem."
    },
    "errors": {
      "invalidIncomingIntegration":
        "Niepoprawne dane wejściowe do integracji! Nie można kontynuować."
    },
    "events": {
      "surveyCompleted": {
        "description":
          "Po zakończeniu ankiety wyniki dla {groupTitle} są automatycznie przesyłane do integracji.",
        "title": "Ankieta ukończona"
      },
      "title": "Wybierz zdarzenie, przy którym ma nastąpić kontakt z integracją"
    },
    "groups": {
      "title": "Wybierz grupę, by połączyć się z {appName}"
    },
    "integrationTo": "Integracja z {appName}",
    "nothingSelected": "Wybierz...",
    "setupIntegration": {
      "saveButtonTitle": "Zapisz",
      "title": "Utwórz integrację z {appName}"
    },
    "title": "Integracje"
  },
  "login": {
    "button": {
      "forgotPassword": "Zapomniałem hasła",
      "submit": "Zaloguj się"
    },
    "captchaLegal": {
      "disclaimer": "Tas",
      "policy": "Polityka prywatności",
      "toc": "Warunki świadczenia usług"
    },
    "header": "Witaj w Puls!",
    "placeholder": {
      "email": "Adres e-mail",
      "organization": "Organizacja",
      "password": "Hasło"
    }
  },
  "navbar": {
    "welcome": "Witaj"
  },
  "results": {
    "participants": "{participants} uczestnicy",
    "surveys": {
      "completed": {
        "latest": "Ostatnia ukończona ankieta",
        "title": "Wyniki dla ukończonej ankiety"
      },
      "published": {
        "title": "Sprawdź wyniki"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info":
          "Jest to konieczne, aby umożliwić zbieranie statystyk dotyczących różnych grup wiekowych.",
        "label": "Data urodzenia"
      },
      "birth_date": {
        "info":
          "Jest to konieczne, aby umożliwić zbieranie statystyk dotyczących różnych grup wiekowych.",
        "label": "Data urodzenia"
      },
      "custom:employed_since": {
        "info":
          "Data zatrudnienia. Nie musi to być konkretna data. Pomiń, jeśli nie jesteś aktualnie zatrudniony.",
        "label": "Zatrudniony od"
      },
      "employment_date": {
        "info":
          "Data zatrudnienia. Nie musi to być konkretna data. Pomiń, jeśli nie jesteś aktualnie zatrudniony.",
        "label": "Zatrudniony od"
      },
      "family_name": {
        "label": "Nazwisko"
      },
      "gender": {
        "few": "Inne",
        "many": "Inne",
        "one": "Inne",
        "other": "Inne",
        "zero": "Inne",
        "female": "Kobieta",
        "info":
          "Prosimy o podanie płci w celu dołączenia jej do statystyk. Nie musisz wybierać niczego, jeśli nie masz na to ochoty.",
        "label": "Wybierz płeć:",
        "male": "Mężczyzna",
        "wontsay": "Wolę nie mówić"
      },
      "given_name": {
        "label": "Imię"
      },
      "locale": {
        "da": "Duński",
        "de": "Niemiecki",
        "dk": "Duński",
        "en": "Angielski",
        "es": "Hiszpański",
        "false": "Norweski",
        "fr": "Francuski",
        "info": "Wybierz język, który chcesz używać. Możesz to potem zmienić.",
        "label": "Wybierz język:",
        "ms": "Malajski",
        "no": "Norweski",
        "sv": "Szwedzki"
      }
    },
    "button": {
      "submit": "Zapisz",
      "tryAgain": "Spróbuj ponownie"
    },
    "error": {
      "badPassword": "Proszę wybrać bardziej bezpieczne hasło.",
      "clickLinkAgain":
        "Proszę jeszcze raz kliknąć link w powitalnym e-mailu. Jeśli problem wystąpi ponownie, proszę skontaktować się zsupport@puls-solutions.com.",
      "invalid": "Ten link nie jest już ważny, zamiast tego kliknij poniżej:",
      "noPassword": "Musisz wybrać hasło.",
      "passwordsMustMatch": "Hasła muszą do siebie pasować.",
      "sessionError": "Napotkaliśmy problem!",
      "SSONotFound": "Nie ma konfiguracji SSO dla tej organizacji.",
      "unexpected": "Wystąpił nieoczekiwany problem."
    },
    "header": "Zacznij korzystać z Puls!",
    "introText":
      "Witaj {name}! Zaproszono cię do Puls, platformy służącej do podwyższania zaangażowania współpracowników ",
    "introTextVolontary": "Obowiązkowe jest jedynie podanie hasła.",
    "login": "Kontynuuj",
    "placeholder": {
      "confirmPassword": "Potwierdź hasło",
      "newPassword": "Wybierz hasło"
    }
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "Kontynuuj"
      },
      "requestCode": {
        "title": "Zdobądź kod jednorazowy"
      },
      "submitCode": {
        "title": "Zaloguj się"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "Na początek chcielibyśmy upewnić się, że jesteś odpowiednią osobą! Kontynuując, otrzymasz jednorazowy kod. Posłuż się nim w następnym kroku, aby zalogować się do Puls w {organizationName}.",
        "help":
          "Niedawno zażądałeś kodu. Upewnij się, że wpisujesz kod w oknie, w którym go zażądałeś. Kontynuując tutaj, otrzymasz nowy kod."
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "kod jednorazowy"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "e-mail",
          "SMS": "SMS"
        },
        "description":
          "Jednorazowy kod został do Ciebie wysłany poprzez {deliveryMedium}. Pozostaw to okno otwarte i wróć do niego, aby wprowadzić kod poniżej:",
        "title": "Witaj w Puls!"
      },
      "help": {
        "noCodeDescriptionEmail":
          "Kod został wysłany w wiadomości e-mail na adres zawierający „{destination}”. Dostarczenie kodu może zająć minutę lub dwie. Jeśli nie możesz go znaleźć, sprawdź folder Spam. Pozostaw to okno otwarte i wprowadź kod powyżej.",
        "noCodeDescriptionSMS":
          "Kod został wysłany w wiadomości tekstowej na Twój numer telefonu komórkowego zawierający „{destination}”. Dostarczenie kodu może zająć minutę lub dwie. Jeśli nie rozpoznajesz tego numeru telefonu, skontaktuj się z administratorem.",
        "noCodeLinkTitle": "Nie otrzymałeś kodu?",
        "noCodeStartOverLinkTitle": "Zacznij od nowa"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "adres e-mail"
        }
      },
      "header": {
        "description": "Wprowadź swój adres e-mail w {organizationName}:"
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "nazwa organizacji"
        }
      },
      "header": {
        "description":
          "Zacznij wpisywać nazwę organizacji, do której należysz.",
        "invalid": {
          "spaces": "Nie używaj spacji.",
          "specialchars": "Używaj wyłącznie liter.",
          "uppercase": "Używaj wyłącznie małych liter."
        }
      }
    }
  },
  "surveys": {
    "description": "Następujące ankiety są teraz aktywne:",
    "descriptionOneSurveyOnly": "Wybierz ankietę poniżej, aby rozpocząć!",
    "descriptionOneSurveyOnlyUserDone":
      "Ankieta wciąż trwa. Wybierz ją, aby sprawdzić, jakie aktywności zostały wybrane.",
    "emptyResult":
      "W tej chwili nie ma ankiety, która czeka na wypełnienie. Możesz spojrzeć na swoje akcje i wyniki poniżej.",
    "ended": "skończyła się",
    "endsIn": "kończy się",
    "retry": "Spróbuj ponownie",
    "title": "Ankiety",
    "youreDone": "Skończyłeś"
  },
  "surveysToFillIn": {
    "description": "Te ankiety jeszcze trwają:",
    "descriptionOneSurveyOnly": "Wybierz poniżej ankietę, aby rozpocząć!",
    "descriptionOneSurveyOnlyUserDone":
      "Ankieta nadal trwa. Wybierz ją, aby zobaczyć podjęte przez Ciebie działania.",
    "emptyResult":
      "W tej chwili nie ma ankiety oczekującej na wypełnienie przez Ciebie. Możesz zobaczyć swoje działania/komentarze lub wyniki tutaj poniżej.",
    "ended": "zakończone",
    "endsIn": "kończy się",
    "retry": "Ponów",
    "title":
      "Masz ankietę do wypełnienia | Masz {count} oczekujących/e ankiet/y",
    "youreDone": "Gotowe"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info":
          "Jest to konieczne, aby umożliwić zbieranie statystyk dotyczących różnych grup wiekowych.",
        "label": "Data urodzenia"
      },
      "birth_date": {
        "info":
          "Jest to konieczne, aby umożliwić zbieranie statystyk dotyczących różnych grup wiekowych.",
        "label": "Data urodzenia"
      },
      "custom:employed_since": {
        "info":
          "Data zatrudnienia. Nie musi to być konkretna data. Pomiń, jeśli nie jesteś aktualnie zatrudniony.",
        "label": "Zatrudniony od"
      },
      "employment_date": {
        "info":
          "Data zatrudnienia. Nie musi to być konkretna data. Pomiń, jeśli nie jesteś aktualnie zatrudniony.",
        "label": "Zatrudniony od"
      },
      "family_name": {
        "label": "Nazwisko"
      },
      "gender": {
        "few": "Inne",
        "many": "Innych",
        "one": "Inne",
        "other": "Inne",
        "zero": "Innych",
        "female": "Kobieta",
        "info":
          "Prosimy o podanie płci w celu dołączenia jej do statystyk. Nie musisz wybierać niczego, jeśli nie masz na to ochoty.",
        "label": "Wybierz płeć:",
        "male": "Mężczyzna",
        "wontsay": "Wolę nie mówić"
      },
      "given_name": {
        "label": "Imię"
      },
      "locale": {
        "da": "Duński",
        "de": "Niemiecki",
        "en": "Angielski",
        "es": "Hiszpański",
        "false": "Norweski",
        "fi": "Fiński",
        "fr": "Francuski",
        "info": "Wybierz język, który chcesz używać. Możesz to potem zmienić.",
        "it": "Włoski",
        "label": "Wybierz język:",
        "ms": "Malajski",
        "nl": "Holenderski",
        "no": "Norweski",
        "pl": "Polski",
        "sv": "Szwedzki"
      }
    },
    "button": {
      "cancel": "Anuluj",
      "skip": "Cofnij",
      "submit": "Zapisz",
      "tryAgain": "Spróbuj ponownie"
    },
    "error": {
      "badPassword": "Proszę wybrać bardziej bezpieczne hasło.",
      "clickLinkAgain":
        "Proszę jeszcze raz kliknąć link w powitalnym e-mailu. Jeśli problem wystąpi ponownie, proszę skontaktować się zsupport@puls-solutions.com.",
      "invalid": "Ten link nie jest już ważny, zamiast tego kliknij poniżej:",
      "noPassword": "Musisz wybrać hasło.",
      "passwordsMustMatch": "Hasła muszą do siebie pasować.",
      "sessionError": "Napotkaliśmy problem!",
      "SSONotFound": "Nie ma konfiguracji SSO dla tej organizacji.",
      "unexpected": "Wystąpił nieoczekiwany problem."
    },
    "header": "Opcje użytkownika",
    "introText": "Sprawdź, czy profil tego użytkownika jest prawidłowy.",
    "introTextVolontary": "Wpisanie poniższych informacji jest dobrowolne.",
    "login": "Kontynuuj"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "Anuluj",
      "done": "OK",
      "send": "Wyślij anonimowo"
    },
    "description":
      "Dajemy Ci możliwość anonimowego wysłania zgłoszenia o bezprawnych zachowaniach lub wykroczeniach, których byłeś świadkiem w {organizationName}.",
    "detailedDescription":
      "Oto, jak to działa. Treść raportu wpisujesz w polu tekstowym poniżej. Kiedy wysyłasz raport, wiadomość e-mail jest wysyłana do zdefiniowanego wcześniej odbiorcy w Twojej organizacji, który jest odpowiedzialny za podjęcie działania. Odbiorca nie może zobaczyć, że wiadomość została wysłana przez Ciebie, a jedynie, że pochodzi z platformy Puls.  Jeśli chcesz, by się z Tobą skontaktowano, zamieść o tym informację w swojej wiadomości. Jeśli chcesz pozostać anonimowy, możesz użyć jednorazowego (tymczasowego) adresu e-mail lub karty SIM do telefonu komórkowego.",
    "doneText":
      "Dziękujemy za przesłanie raportu. Potraktujemy go poważnie. Jeśli podałeś dane kontaktowe, odezwiemy się do Ciebie tak szybko, jak to możliwe!",
    "linkTitle": "Dowiedz się więcej tutaj",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description":
        "Przepraszamy. Twoja organizacja nie ma włączonej funkcji informatora o nieprawidłowościach (sygnalisty).",
      "title": "Niedostępne"
    },
    "placeholder":
      "Tutaj wpisz swój raport. Bądź tak konkretny, jak to tylko możliwe i daj nam znać, czy i jak chcesz, by się z Tobą skontaktowano.",
    "title": "Wyślij anonimowy raport"
  }
}
