export default {
  "completePassword": {
    "button": {
      "submit": "Dir"
    },
    "header": "Dooro baasaweer cusub",
    "placeholder": {
      "confirmPassword": "Xaqiiji baasaweerka",
      "password": "Baasaweerka"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "Dhammaan natiijooyinka",
      "myActions": "TALLAABOOYINKAYGA",
      "ourCompanyResults": "NATIIJOOYINKEENA"
    },
    "card": {
      "body":
        "Halkaan waxaad ka helaysaa tallaabooyinka shaqsi ee aad dooratay inaad sameyso. Sidoo kale waxaad arki kartaa natiijadaada iyo sida loola barbardhigi karo ururka guud.",
      "title": "Tallaabooyinka iyo natiijooyinka"
    },
    "greetings": {
      "day": "Setahay, {username}!",
      "evening": "Fiidnimo wanaagsan, {username}!",
      "morning": "Subax wanaagsan, {username}!",
      "night": "Habbeen wanaagsan, {username}!"
    },
    "hello": "Helloow {username}!",
    "integrations": {
      "title": "Is-dhexgalka"
    },
    "introHeadline": "{productTitle} ee {organizationName}",
    "settings": {
      "ar": "Carabi",
      "body": "Halkaan waxaad ka badeli kartaa sitinka akoonkaaga.",
      "da": "Danish",
      "de": "Jarmal",
      "en": "Ingiriis",
      "false": "Norwegian",
      "fr": "Faransiis",
      "logout": {
        "button": {
          "title": "Ka bax"
        },
        "confirmation": {
          "areyousure":
            "Haddii aad ka baxdo waxaad u baahan doontaa inaad codsato koodh hal mar la isticmaali karo si aad mar labaad dib ugu gasho.",
          "cancel": "Jooji",
          "false": "Jooji",
          "no": "Jooji",
          "title": "Ma waxaad dooneysaa inaad ka baxdo?",
          "true": "Ka bax",
          "yes": "Ka bax"
        },
        "loggedin": "Waxaad tahay isticmaale kujira oo isticmaalaya {username}."
      },
      "no": "Norwegian",
      "sv": "Swedish",
      "title": "Sitinada"
    }
  },
  "error": {
    "CodeMismatchException":
      "Koodhka halka mar ah ee aad gelisay waa qalad. Fadlan hubi xogta labo jeer oo iskuday mar labaad.",
    "couldNotLoadResults":
      "Lama soo bixin karo natiijooyinka, fadlan iskuday mar labaad.",
    "couldNotLoadSurveys":
      "Lama soo bixin karo xog aruurinta, fadlan iskuday mar labaad.",
    "emailAtLeast3Letter":
      "Ciwaanka iimaylka waa inuu leeyahay ugu yaraan 3 xaraf",
    "emptyEmail": "Geli ciwaanka iimaylka",
    "emptyOrganization": "Geli magaca ururkaaga",
    "emptyPassword": "Geli baasaweer",
    "ExpiredCodeException":
      "Koodhka halka mar ah ee aad gelisay waa mid hore sababo la xariirana amniga ayaan loo isticmaali karin.",
    "InvalidParameterException":
      "Qaybya doorsoomayaasha la iska rabo ee maqan.",
    "InvalidPasswordException": "Fadlan dooro baasaweer aad badqab u ah.",
    "LimitExceededException":
      "In badan ood iskudayday ayaad ku guuldareysatay. Waa inaad sugto muddo inta aadan isku dayin mar labaad.",
    "NotAuthorizedException":
      "Waqti xaadirkaan macquul ma ahan inaad ku gasho magacaaga.",
    "notMatchPassword": "Baasaweerku waa kaladuwan yahay!",
    "OrganizationNotFoundException":
      "Ma heli karno urur leh magacaas, fadlan iskuday mar labaad.",
    "UserNotFoundException": "Ma heli karno akoon isticmaale oo leh {username}."
  },
  "forgotten": {
    "backToLogin": "Jooji",
    "helptext":
      "Halkaan waxaad ka cusbooneysiin kartaa baasaweerkaaga. Waxaad iimayl ku heli doontaa ciwaanka iimaylka kore oo wata koodh, oo aad gelin doonto tallaabaada xiggta.",
    "helptext-quickstart":
      "Waxaan hadda kusoo dirtay koodh kumeel gaar ah oo aan kusoo dirtau {email}. Hoos geli, oo dooro baasaweer cusub.",
    "helptext2": "Dooro baasaweer badqab ah.",
    "input": {
      "code": "Koodhka kujira iimaylka",
      "organization": "Urur",
      "password": "Baasaweer cusub",
      "username": "Ciwaanka iimaylka"
    },
    "requestcode": {
      "submit": "Sii wad"
    },
    "setNewPassword": {
      "submit": "Billoow!"
    },
    "wait": "Fadlan sug..."
  },
  "general": {
    "defaultProductTitle": "Puls⁺"
  },
  "help": {
    "card": {
      "body":
        "Majiraan wax aan kaa caawin karno? Kaliya guji batoonka hoose si aad u aado xarunta caawimaada.",
      "title": "Caawimaada iyo taageerada"
    },
    "description": "Ha welwelin, gacan ayaan kusiineynaa.",
    "title": "CAAWIMAAD"
  },
  "ie": {
    "detailedText":
      "Aalada Puls waxaa loo adeegsan karaa Internet Explorer laakiin waa boroowsar hore oo aan u hogaansamin xeerarka casriga ah ee webka. Sidaas oowgeed waxaan ku talinaynaa inaad isticmaasho mid kamid ah boroowsaradaan hoose. Sidoo kale waxaad isticmaali kartaa taleefoonkaaga gacanta.",
    "detailedTitle": "Xog ku saabsan Internet Explorer",
    "readMore": "Akhri xog dheeraad ah",
    "warning":
      "Waxaad isticmaalaysaa Internet Explorer. Waxaan ku talinaynaa inaad u badelato boroowsar web oo kale si uu kuugu habboonaado."
  },
  "integrations": {
    "addIntegrationSuccess": "Isdhexgalka si guul leh ayaa loogu daray!",
    "allIntegrations": "Dhammaan Isdhexgalada",
    "apps": {
      "sftp": {
        "connectionInfo": "Waxaan soo gelin doonaa {dir} saafarka SFTP.",
        "connectionTitle": "Khadka SFTP",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "Ku xiran {teamName} janaalka {channel}",
        "connectionTitle": "U kala horeysii {appDetail}",
        "title": "Kala horeynta"
      }
    },
    "back": "Ka noqo",
    "buttons": {
      "deleteIntegration": {
        "help": "Kani waxay ka saaray {type} aalada Puls."
      }
    },
    "connectedSince": "Ku xiran ilaa {date}",
    "empty": {
      "description":
        "Kadib marka aad ku darto isdhexgalka waxaad khadka Puls ka dhigi kartaa adeegg kuu gaar ah. Dooro batoonka hoose si aad ugu xirnaato adeegga."
    },
    "errors": {
      "invalidIncomingIntegration":
        "Xogta soo socota ee qaldan lana xariirta isdhexgalka! Lama sii wadi karo."
    },
    "events": {
      "surveyCompleted": {
        "description":
          "Marka natiijooyinka xog aruurintu dhammaato ee la xariira {groupTitle} waxaa si ootamaatig ah loogu diri doonaa isdhexgalka.",
        "title": "Xog aruurinta la dhameeyay"
      },
      "title": "Dooro dhacdo marka isdhexgalka ay tahay inaan lala xariirin"
    },
    "groups": {
      "title": "Dooro koox si aad ugu xirnaato {appName}"
    },
    "integrationTo": "Isdhexaglka {appName}",
    "nothingSelected": "Dooro...",
    "setupIntegration": {
      "saveButtonTitle": "Seyf garee",
      "title": "Deji isdhexgalka {appName}"
    },
    "title": "Is-dhexgalka"
  },
  "loggedOut": {
    "loginButton": {
      "title": "Gal mar labaad"
    },
    "title": "Hadda waad ka baxday!"
  },
  "login": {
    "button": {
      "forgotPassword": "Waan ilaaway baasaweerka",
      "submit": "Gal"
    },
    "captchaLegal": {
      "disclaimer":
        "Bartaan waxaa ilaaliya reCAPTCHA iyo Google {toc} iyo {privacyPolicy} codso.",
      "policy": "Xeerka asturnaanta Xogta",
      "toc": "Shuruudaha Adeegga"
    },
    "header": "Gal",
    "placeholder": {
      "email": "Ciwaanka iimaylka",
      "organization": "Urur",
      "password": "Baasaweerka"
    }
  },
  "navbar": {
    "welcome": "Soo dhawaaw"
  },
  "notFound": {
    "alert": "Boggaan majiro. Fadlan dib ugu laabo meesha aad ka timaaday",
    "backButton": "Guudmar",
    "title": "Lama helin"
  },
  "results": {
    "participants": "{participants} ka qaybgalayaasha",
    "surveys": {
      "completed": {
        "latest": "Xog aruurinada la dhameystiray ee ugu danbeysay",
        "title": "Natiijooyinka xog aruurinta la dhameeyay"
      },
      "emptyResult":
        "Mar dhaw waxaad awoodi doontaa inaad halkaan ku aragto natiijooyinka!",
      "published": {
        "title": "Eeg natiijooyinka"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info":
          "Tani waa muhiim si loo aruuriyo xog aruurinta ku saleysan da'da kooxaha kaladuwan.",
        "label": "Taariikjda dhalashada"
      },
      "birth_date": {
        "info":
          "Tani waa muhiim si loo aruuriyo xog aruurinta ku saleysan da'da kooxaha kaladuwan.",
        "label": "Taariikjda dhalashada"
      },
      "custom:employed_since": {
        "info":
          "Taariikhda lagu shaqaaleysiiyay. Qasab ma ahan inay tahay taariikhda saxda ah. Ka bood doorashada haddii aadan ahayn shaqaale.",
        "label": "Shaqeynayay ilaa"
      },
      "employment_date": {
        "info":
          "Taariikhda lagu shaqaaleysiiyay. Qasab ma ahan inay tahay taariikhda saxda ah. Ka bood doorashada haddii aadan ahayn shaqaale.",
        "label": "Shaqeynayay ilaa"
      },
      "family_name": {
        "label": "Magaca ugu danbeeya"
      },
      "gender": {
        "other": "Kale",
        "female": "Dhedig",
        "info":
          "Waxaan ku waydiinaynaa jinsigaaga si aan ugu darto xogaha. Ma ahan inaad doorato mid uun haddii aadan dooneyn.",
        "label": "Dooro jinsiga:",
        "male": "Lab",
        "wontsay": "Waxaan doorbidayaa inaanan sheegin"
      },
      "given_name": {
        "label": "Magaca koowaad"
      },
      "locale": {
        "ar": "Carabi",
        "da": "Danish",
        "de": "Jarmal",
        "dk": "Danish",
        "en": "Ingiriis",
        "es": "Isbaanish",
        "false": "Norwegian",
        "fr": "Faransiis",
        "info":
          "Dooro luuqada aad jeceshahay inaad adeegsato. Tani sidoo kale hadhow waad badeli kartaa.",
        "label": "Dooro luuqad:",
        "ms": "Malay",
        "no": "Norwegian",
        "sv": "Swedish"
      }
    },
    "button": {
      "submit": "Seyf garee",
      "tryAgain": "Mar labaad iskuday"
    },
    "error": {
      "badPassword": "Fadlan dooro baasaweer aad badqab u leh.",
      "clickLinkAgain":
        "Fadlan guji liifaaqa kujira iimaylka soo dhaweynta mar labaad. Haddii ciladu sii jirto fadlan la xariir support@puls-solutions.com",
      "invalid": "Lifaaqaas wali ma shaqeynaayo, taas badelkeeda guji hoos:",
      "noPassword": "Waa inaad doorato baasaweer.",
      "passwordsMustMatch": "Baasaweeradu waa inay isku mid yihiin.",
      "sessionError": "Waan la helay cilad!",
      "SSONotFound": "Majiro habraaca SSO ee ururka.",
      "unexpected": "Waxaa jiray cilad aan la filayn"
    },
    "header": "Billoow Puls!",
    "introText":
      "Helloow{name}! Waxaa lagugu casuumay Puls, oo ah aalad ay ku wada xariiraan dadka wada shaqeeya. Furo akoonkaaga oo buuxi foomkaan hoose. ",
    "introTextVolontary":
      "Kaliya baasaweerka ayaa qasab ah inaad geliso si aad u buuxiso.",
    "login": "Sii wad",
    "placeholder": {
      "confirmPassword": "Xaqiiji baasaweerka",
      "newPassword": "Dooro baasaweer"
    }
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "Sii wad"
      },
      "requestCode": {
        "title": "Hel koodhka halka waqti ah"
      },
      "submitCode": {
        "title": "Gal"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "Waxaan marka koowaad dooneynaa inaan hubino inaad tahay qofka saxda ah! Marka aad sii wado waxaa laguu soo diri doonaa koodhka halka waqti. Isticmaal tallaabada xigta si aad u gasho {productTitle} gaar ahaan {organizationName}.",
        "help":
          "Waxaad dhawaan codsatay koodh. Xaqiiji inaad koodhka geliso shaashada halka aad ka codsatay. Haddii aad sii wado halkaan waxaa lagugu soo diri doonaa koodh cusub."
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "koodhka halka waqti ah"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "iimayl",
          "SMS": "fariin qoraal ah"
        },
        "description":
          "Koodhka halka waqti waxaa lagu soo diray {deliveryMedium}. Dhaaf shaashadaad oo furan oo dib ugu soo laabo si aad u geliso koodhka hoose:",
        "title": "Kusoo dhawaaw {productTitle}!"
      },
      "help": {
        "noCodeDescriptionEmail":
          "Koodhka waxaa laguugu soo diray iimaylka ayadoo la adeegsanaayo ciwaanka iimaylka waxaana kujira '{destination}'. Wuxuu qaadan karaa daqiiqad ama labo si aad u hesho koodhka. Haddii aadan heli karin eeg boqshada fariimaha spamka ah. Xaqiiji inaad shaashadaan oo furan dhaafto aadna geliso koodhka kore.",
        "noCodeDescriptionSMS":
          "Koodhka waxaa laguugu soo diray fariin ahaan taleefoonkaaga gacanta oo lambarka taleefoonka kujira '{destination}'. Wuxuu qaadan karaa daqiiqad ama labo si aad u hesho koodhka. Haddii aadan aqoon lambarka taleefoonka, fadlan la xariir maamulaha.",
        "noCodeLinkTitle": "Ma aadan helin miyaa koodh?",
        "noCodeStartOverLinkTitle": "Mar labaad billoow"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "ciwaanka iimaylka"
        }
      },
      "header": {
        "description": "Geli ciwaanka iimaylka {organizationName}:"
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "magaca ururka"
        }
      },
      "header": {
        "description":
          "Ku billoow inaad geliso magaca ururka aad ka tirsan tahay.",
        "invalid": {
          "spaces": "Iska ilaali inaad meelo baneyso.",
          "specialchars": "Kaliya isticmaal xarafyo.",
          "uppercase": "Kaliya isticmaal xarafyada shaqal kaabka ah."
        }
      }
    }
  },
  "surveys": {
    "description": "Xog aruurinadaan waa ay socdaan hadda:",
    "descriptionOneSurveyOnly": "Dooro xog aruurinta hoose si aad u billoowdo!",
    "descriptionOneSurveyOnlyUserDone":
      "Xog aruurintu wali waa socotaa. Dooro si aad u aragto tallaabooyinka aad dooratay.",
    "ended": "dhammaad",
    "youreDone": "Waad dhameysay"
  },
  "surveysToFillIn": {
    "description": "Xog aruurinadaan waa ay socdaan hadda:",
    "descriptionOneSurveyOnly": "Dooro xog aruurinta hoose si aad u billoowdo!",
    "descriptionOneSurveyOnlyUserDone":
      "Xog aruurintu wali waa socotaa. Dooro si aad u aragto tallaabooyinka aad dooratay.",
    "emptyResult":
      "Hadda ma jiro xog uruurin kaa sugaya inaad buuxiso. Waxaad ka eegi kartaa ficilladaada/faallooyinkaaga ama natiijooyinkaaga halkaan hoose.",
    "ended": "dhammaad",
    "endsIn": "dhammaanaya",
    "retry": "Iskuday mar kale",
    "title":
      "Xog aruurinta hadda|Waxaad haysataa xog aruurin ay tahay inaad buuxiso|Waxaa jira {count} xog aruurinood oo ku sugaaya",
    "youreDone": "Waad dhameysay"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info":
          "Tani waa muhiim si loo aruuriyo xog aruurinta ku saleysan da'da kooxaha kaladuwan.",
        "label": "Taariikjda dhalashada"
      },
      "birth_date": {
        "info":
          "Tani waa muhiim si loo aruuriyo xog aruurinta ku saleysan da'da kooxaha kaladuwan.",
        "label": "Taariikjda dhalashada"
      },
      "custom:employed_since": {
        "info":
          "Taariikhda lagu shaqaaleysiiyay. Qasab ma ahan inay tahay taariikhda saxda ah. Ka bood doorashada haddii aadan ahayn shaqaale.",
        "label": "Shaqeynayay ilaa"
      },
      "employment_date": {
        "info":
          "Taariikhda lagu shaqaaleysiiyay. Qasab ma ahan inay tahay taariikhda saxda ah. Ka bood doorashada haddii aadan ahayn shaqaale.",
        "label": "Shaqeynayay ilaa"
      },
      "family_name": {
        "label": "Magaca ugu danbeeya"
      },
      "gender": {
        "other": "Kale",
        "female": "Dhedig",
        "info":
          "Waxaan ku waydiinaynaa jinsigaaga si aan ugu darto xogaha. Ma ahan inaad doorato mid uun haddii aadan dooneyn.",
        "label": "Dooro jinsiga:",
        "male": "Lab",
        "wontsay": "Waxaan doorbidayaa inaanan sheegin"
      },
      "given_name": {
        "label": "Magaca koowaad"
      },
      "locale": {
        "ar": "Carabi",
        "da": "Danish",
        "de": "Jarmal",
        "en": "Ingiriis",
        "es": "Isbaanish",
        "false": "Norwegian",
        "fi": "Finnish",
        "fr": "Faransiis",
        "info":
          "Dooro luuqada aad jeceshahay inaad adeegsato. Tani sidoo kale hadhow waad badeli kartaa.",
        "it": "Af talyaani",
        "label": "Dooro luuqad:",
        "ms": "Malay",
        "nl": "Dutch",
        "no": "Norwegian",
        "pl": "Polish",
        "sv": "Swedish"
      }
    },
    "button": {
      "cancel": "Jooji",
      "skip": "Dib u laabo",
      "submit": "Seyf garee",
      "tryAgain": "Mar labaad iskuday"
    },
    "error": {
      "badPassword": "Fadlan dooro baasaweer aad badqab u leh.",
      "clickLinkAgain":
        "Fadlan guji liifaaqa kujira iimaylka soo dhaweynta mar labaad. Haddii ciladu sii jirto fadlan la xariir support@puls-solutions.com",
      "invalid": "Lifaaqaas wali ma shaqeynaayo, taas badelkeeda guji hoos:",
      "noPassword": "Waa inaad doorato baasaweer.",
      "passwordsMustMatch": "Baasaweeradu waa inay isku mid yihiin.",
      "sessionError": "Waan la helay cilad!",
      "SSONotFound": "Majiro habraaca SSO ee ururka.",
      "unexpected": "Waxaa jiray cilad aan la filayn"
    },
    "header": "Sitinka isticmaalaha",
    "introText": "Hubi in borofaylkaaga uu saxan yahay.",
    "introTextNoAttributes":
      "Hadda majiro sitino ay tahay in halkaan laga badelo.",
    "introTextVolontary": "Xog hoose waa ikhtiyaari in la buuxiyo.",
    "login": "Sii wad"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "Jooji",
      "done": "Okay",
      "send": "Si qarsoodi ah u dir"
    },
    "description":
      "Waxaanu ku siinaynaa suurtagalnimada inaad si qarsoodi ah u soo dirto warbixin ku saabsan hab dhaqanka sharci darrada ah ama gefafka aad ku aragtay {organizationName}.",
    "detailedDescription":
      "Sidaan waa sida ay u shaqeyso. Waxaad gelinaysaa warbixintaada qaybta qoraalka ee bokiska hoose. Markaad dirto warbixinta iimayl ayaa loo soo diraa qaataha horay loo sii qeexay ee ururkaaga oo masuul ka ah inuu tallaabo qaado. Macquul ma ahan in qofka helaaya uu oggaado inaad adigu soo dirtay fariinta, kaliya fariintu waxay ka imaanaysaa aalada Puls.  Haddii aad dooneyso in lagula soo xariiro, xaqiiji inaad ku darto xogta meelaha lagaala soo xariiri karo fariintaada. Haddii aad rabto inaad ahaato qarsoodi waxaad isticmaali kartaa ciwaanka iimaylka ah ee la tuuri karo (ku meel gaarka ah) ama kaarka SIM ee taleefanka gacanta.",
    "doneText":
      "Waad ku mahadsan tahay inaad soo bixisay warbixintaada. Waxaan u qaadan doonaa si dhab ah. Haddii aad bixisay meel lagaala soo xariiro dib ayaan kuula soo xariiri doonaa sida ugu dhaqsiyaha badan!",
    "linkTitle": "Halkaan xog dheeraad ah ka akhri",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description":
        "Raali-gelin. Qaybta lagu soo gudbiyo qaladaadka lagama heli karo ururkaaga.",
      "title": "Lama heli karo"
    },
    "placeholder":
      "Halkaan geli warbixintaada. Si faahfaahsan u qeex intii suurtagal ah oo noo sheeg haddii aad dooneyso in lagula soo xariiro iyo qaabka laguula soo xariiraayo.",
    "title": "Soo dir warbixin qarsoodi ah"
  }
}
