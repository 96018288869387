export default {
  "completePassword": {
    "button": {
      "submit": "Envoyer"
    },
    "header": "Choisissez un nouveau mot de passe",
    "placeholder": {
      "confirmPassword": "Confirmez le mot de passe",
      "password": "Mot de passe"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "Tous les résultats",
      "myActions": "MES MESURES",
      "ourCompanyResults": "NOS RÉSULTATS"
    },
    "card": {
      "body":
        "Vous trouverez ici les mesures que vous avez choisi de prendre. Vous pouvez également voir vos résultats comparés aux personnes de votre groupe ou de votre organisation.",
      "title": "Activités et résultats"
    },
    "hello": "Hallo {username}!",
    "introHeadline": "{productTitle} à {organizationName}",
    "settings": {
      "body": "Ici, vous pouvez modifier la langue utilisée.",
      "da": "Danois",
      "de": "Allemande",
      "en": "Anglaise",
      "false": "Norvégien",
      "fr": "Française",
      "logout": {
        "button": {
          "title": "Déconnexion"
        },
        "confirmation": {
          "areyousure":
            "Si vous vous déconnectez, vous devez demander un nouveau code unique afin de vous connecter à nouveau.",
          "cancel": "Annuler",
          "false": "Annuler",
          "no": "Annuler",
          "title": "Vous voulez vous déconnecter ?",
          "true": "Déconnexion",
          "yes": "Déconnexion"
        },
        "loggedin": "Vous êtes connecté en tant que {username}."
      },
      "no": "Norvégien",
      "sv": "Suédoise",
      "title": "Paramètres"
    }
  },
  "error": {
    "CodeMismatchException":
      "Le code unique que vous avez saisi n'est pas valide. Veuillez le revérifier et réessayer.",
    "couldNotLoadResults":
      "Impossible de charger les résultats ; veuillez réessayer.",
    "couldNotLoadSurveys":
      "Impossible de charger les sondages ; veuillez réessayer.",
    "emailAtLeast3Letter": "L'adresse e-mail doit contenir au moins 3 lettres",
    "emptyEmail": "Saisissez votre adresse e-mail",
    "emptyOrganization": "Saisissez le nom de votre organisation",
    "emptyPassword": "Saisissez un mot de passe",
    "ExpiredCodeException":
      "Le code unique que vous avez saisi est trop ancien et ne peut pas être utilisé pour des raisons de sécurité.",
    "InvalidParameterException": "Session de paramètres obligatoire manquante.",
    "InvalidPasswordException":
      "Veuillez choisissez un mot de passe plus sécurisé.",
    "LimitExceededException":
      "Trop de tentatives de connexion. Vous devez attendre un certain temps avant de réessayer.",
    "NotAuthorizedException":
      "Il n'est actuellement pas possible de se connecter avec votre compte utilisateur.",
    "notMatchPassword": "Les mots de passe ne correspondent pas !",
    "OrganizationNotFoundException":
      "Nous n'avons pas pu trouver une organisation portant ce nom ; veuillez réessayer.",
    "UserNotFoundException":
      "Nous n'avons pas pu trouver un utilisateur enregistré avec {username}."
  },
  "forgotten": {
    "backToLogin": "Annuler",
    "helptext":
      "Ici, vous pouvez réinitialiser votre mot de passe ; vous recevrez un e-mail à l'adresse e-mail ci-dessus avec un code que vous saisirez à l'étape suivante.",
    "helptext-quickstart":
      "Nous venons de vous envoyer un code temporaire à {email}. Entrez le ci-dessous, et choisissez un mot de passe.",
    "helptext2": "Choisissez un mot de passe sécurisé.",
    "input": {
      "code": "Le code se trouve dans l'e-mail",
      "organization": "Organisation",
      "password": "Nouveau mot de passe",
      "username": "Adresse e-mail"
    },
    "requestcode": {
      "submit": "Continuer"
    },
    "setNewPassword": {
      "submit": "Commencer !"
    },
    "wait": "S'il vous plaît, patientez..."
  },
  "general": {
    "defaultProductTitle": "Puls⁺"
  },
  "help": {
    "card": {
      "body": "Pouvons-nous faire quelque chose pour vous aider ?",
      "title": "Assistance et soutien"
    },
    "description": "Ne vous inquiétez pas, nous sommes là pour vous aider.",
    "title": "AIDE"
  },
  "ie": {
    "detailedText":
      "La plateforme Puls peut être utilisée avec Internet Explorer mais c'est un vieux navigateur web qui ne répond pas aux standards modernes du web. Nous vous recommandons donc d'utiliser l'un des navigateurs ci-dessous. Vous pouvez également utiliser votre téléphone portable.",
    "detailedTitle": "À propos d'Internet Explorer",
    "readMore": "Lire la suite",
    "warning":
      "Vous utilisez Internet Explorer ; nous vous recommandons de passer à un autre navigateur Web pour une meilleure expérience."
  },
  "integrations": {
    "allIntegrations": "Toutes les intégrations",
    "apps": {
      "sftp": {
        "connectionInfo": "Téléchargera vers {dir} sur le serveur SFTP.",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "Connecté à {teamName} canal {channel}",
        "title": "Slack"
      }
    },
    "connectedSince": "Connecté depuis {date}",
    "empty": {
      "description":
        "En ajoutant une intégration, vous pouvez faire en sorte que Puls se connecte à votre propre service. Sélectionner le bouton ci-dessous pour vous connecter à un service."
    },
    "errors": {
      "invalidIncomingIntegration":
        "Données entrantes non valides pour l’intégration ! Impossible de continuer."
    },
    "events": {
      "surveyCompleted": {
        "description":
          "Lorsqu’une enquête est terminée, les résultats pour {groupTitle} sont automatiquement envoyés à l’intégration.",
        "title": "Enquête terminée"
      },
      "title":
        "Choisir l’événement pour lequel l’intégration doit être contactée"
    },
    "groups": {
      "title": "Choisir le groupe pour vous connecter à {appName}"
    },
    "integrationTo": "Intégration dans {appName}",
    "nothingSelected": "Sélectionner...",
    "setupIntegration": {
      "saveButtonTitle": "Sauvegarder",
      "title": "Configurer l’intégration dans {appName}"
    },
    "title": "Intégrations"
  },
  "login": {
    "button": {
      "forgotPassword": "Mot de passe oublié",
      "submit": "Connexion"
    },
    "captchaLegal": {
      "disclaimer":
        "Ce site est protégé par reCAPTCHA et les {toc} et {privacyPolicy} de Google s'appliquent.",
      "policy": "Politique de confidentialité",
      "toc": "Conditions générales d'utilisation"
    },
    "header": "Bienvenue à Puls!",
    "placeholder": {
      "email": "Adresse e-mail",
      "organization": "Organisation",
      "password": "Mot de passe"
    }
  },
  "navbar": {
    "welcome": "Bienvenue"
  },
  "results": {
    "participants": "{participants} participants",
    "surveys": {
      "completed": {
        "latest": "Dernière enquête complétée",
        "title": "Resultats pour les enquêtes complétés"
      },
      "published": {
        "title": "Vérifier les résultats"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info":
          "Ceci est nécessaire afin de collecter des statistiques basées sur les différents groupes d'âge.",
        "label": "Date de naissance"
      },
      "birth_date": {
        "info":
          "Ceci est nécessaire afin de collecter des statistiques basées sur les différents groupes d'âge.",
        "label": "Date de naissance"
      },
      "custom:employed_since": {
        "info":
          "La date à laquelle vous étiez employé. Ça n'a pas besoin d'être la date exacte. Ne faites pas de choix si vous n'êtes pas employé.",
        "label": "Employé depuis"
      },
      "employment_date": {
        "info":
          "La date à laquelle vous étiez employé. Ça n'a pas besoin d'être la date exacte. Ne faites pas de choix si vous n'êtes pas employé.",
        "label": "Date d'emploi"
      },
      "family_name": {
        "label": "Nom de famille"
      },
      "gender": {
        "other": "Autre",
        "female": "Femelle",
        "info":
          "We ask about gender so that it can included it in statistics. You don't have to select annything if you don't want to.",
        "label": "Choisir un genre:",
        "male": "Mâle",
        "wontsay": "Je préfère ne pas le dire"
      },
      "given_name": {
        "label": "Prénom"
      },
      "locale": {
        "da": "Danois",
        "de": "Allemande",
        "dk": "Danois",
        "en": "Anglaise",
        "es": "Espagnol",
        "false": "Norvégien",
        "fr": "Française",
        "info":
          "Choose the language you prefer to use. This can also be changed later.",
        "label": "Choisissez la langue:",
        "ms": "Malais",
        "no": "Norvégien",
        "sv": "Suédoise"
      }
    },
    "button": {
      "submit": "Enregistrer",
      "tryAgain": "Réessayer"
    },
    "error": {
      "badPassword": "Veuillez sélectionner un mot de passe plus sécurisé.",
      "clickLinkAgain":
        "Veuillez cliquer à nouveau sur le lien dans l'e-mail de bienvenue ; si le problème persiste, veuillez contacter support@puls-solutions.com",
      "invalid": "Ce lien n'est plus valide.",
      "noPassword": "Vous devez choisir un mot de passe.",
      "passwordsMustMatch": "Les mots de passe doivent correspondre.",
      "sessionError": "Nous avons rencontré un problème !",
      "SSONotFound": "Il n'y a pas de configuration SSO pour l'organisation.",
      "unexpected": "Un problème inattendu s'est produit"
    },
    "header": "Se lancer avec Puls!",
    "introText":
      "Hallo{name}! Vous avez été invité à Puls, une plateforme d'engagement des collègues. Activez votre compte en remplissant le formulaire ci-dessous. ",
    "introTextVolontary": "Seul le mot de passe est obligatoire.",
    "login": "Continuer",
    "placeholder": {
      "confirmPassword": "Confirmer le mot de passe",
      "newPassword": "Choisissez un mot de passe"
    }
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "Continuer"
      },
      "requestCode": {
        "title": "Récupérer un code à usage unique"
      },
      "submitCode": {
        "title": "Connexion"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "Nous voulons d'abord simplement vérifier que vous êtes la bonne personne ! Lorsque vous continuez, un code unique vous sera envoyé. Utilisez-le lors de la prochaine étape pour vous connecter à Puls à {organizationName}.",
        "help":
          "Vous avez récemment fait une demande d'envoi de code. Assurez-vous de rentrer le code dans la fenêtre où il est demandé. Si vous continuez ici, un nouveau code vous sera envoyé."
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "code unique"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "e-mail",
          "SMS": "SMS"
        },
        "description":
          "Un code unique vous a été envoyé via {deliveryMedium}. Utilisez-le ci-dessous pour vous connecter !",
        "title": "Bienvenue à Puls !"
      },
      "help": {
        "noCodeDescriptionEmail":
          "Le code vous a été envoyé par e-mail à l'adresse électronique qui contient {destination}. Le code peut prendre une minute ou deux pour arriver. Si vous ne le trouvez pas, vérifiez dans vos spams. Assurez-vous de garder cette fenêtre ouverte et entrez le code ci-dessus.",
        "noCodeDescriptionSMS":
          "Le code a été envoyé par SMS sur votre numéro de téléphone portable qui contient {destination}. Le code peut prendre une minute ou deux pour arriver. Si vous ne reconnaissez pas le numéro de téléphone, veuillez contacter votre administrateur.",
        "noCodeLinkTitle": "Vous n'avez pas reçu de code ?",
        "noCodeStartOverLinkTitle": "Recommencer depuis le début"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "adresse e-mail"
        }
      },
      "header": {
        "description": "Entrez votre adresse e-mail à {organizationName} :"
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "nom de l'organisation"
        }
      },
      "header": {
        "description":
          "Commencez par entrer le nom de l'organisation dans laquelle vous travaillez.",
        "invalid": {
          "spaces": "N'utilisez pas d'espaces.",
          "specialchars": "N'utilisez que des lettres.",
          "uppercase": "N'utilisez que des lettres minuscules."
        }
      }
    }
  },
  "surveys": {
    "description":
      "Voici les sondages auxquelles vous avez été invité à participer.",
    "descriptionOneSurveyOnly":
      "Sélectionnez le sondage ci-dessous pour commencer !",
    "descriptionOneSurveyOnlyUserDone":
      "L'enquête est toujours en cours. Sélectionnez-la pour voir les activités que vous avez choisies.",
    "emptyResult": "Aucune enquête à laquelle répondre actuellement.",
    "ended": "Terminé",
    "endsIn": "Se termine dans",
    "retry": "Réessayez",
    "title": "Sondages",
    "youreDone": "Vous avez terminé"
  },
  "surveysToFillIn": {
    "description": "Ces enquêtes sont actuellement en cours :",
    "descriptionOneSurveyOnly":
      "Sélectionnez l’enquête ci-dessous pour commencer !",
    "descriptionOneSurveyOnlyUserDone":
      "L’enquête est toujours en cours. Sélectionnez-la pour voir les actions que vous avez choisies.",
    "emptyResult":
      "Pour l’instant, il n’y a pas d’enquête à laquelle vous devez répondre. Vous pouvez jeter un coup d’œil à vos actions/commentaires ou résultats ci-dessous.",
    "ended": "terminé",
    "endsIn": "se termine",
    "retry": "Réessayer",
    "title":
      "Vous avez une enquête à remplir|Il y a {count} enquêtes qui vous attendent",
    "youreDone": "Vous avez terminé"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info":
          "Ceci est nécessaire afin de collecter des statistiques basées sur les différents groupes d'âge.",
        "label": "Date de naissance"
      },
      "birth_date": {
        "info":
          "Ceci est nécessaire afin de collecter des statistiques basées sur les différents groupes d'âge.",
        "label": "Date de naissance"
      },
      "custom:employed_since": {
        "info":
          "La date à laquelle vous étiez employé. Ça n'a pas besoin d'être la date exacte. Ne faites pas de choix si vous n'êtes pas employé.",
        "label": "Employé depuis"
      },
      "employment_date": {
        "info":
          "La date à laquelle vous étiez employé. Ça n'a pas besoin d'être la date exacte. Ne faites pas de choix si vous n'êtes pas employé.",
        "label": "Date d'emploi"
      },
      "family_name": {
        "label": "Nom de famille"
      },
      "gender": {
        "other": "Autre",
        "female": "Femelle",
        "info":
          "We ask about gender so that it can included it in statistics. You don't have to select annything if you don't want to.",
        "label": "Choisir un genre:",
        "male": "Mâle",
        "wontsay": "Je préfère ne pas le dire"
      },
      "given_name": {
        "label": "Prénom"
      },
      "locale": {
        "da": "Danois",
        "de": "Allemande",
        "en": "Anglaise",
        "es": "Espagnol",
        "false": "Norvégien",
        "fi": "Finnois",
        "fr": "Française",
        "info":
          "Choose the language you prefer to use. This can also be changed later.",
        "it": "Italien",
        "label": "Choisissez la langue:",
        "ms": "Malais",
        "nl": "Néerlandais",
        "no": "Norvégien",
        "pl": "Polonais",
        "sv": "Suédoise"
      }
    },
    "button": {
      "cancel": "Annuler",
      "skip": "Retour",
      "submit": "Enregistrer",
      "tryAgain": "Réessayer"
    },
    "error": {
      "badPassword": "Veuillez sélectionner un mot de passe plus sécurisé.",
      "clickLinkAgain":
        "Veuillez cliquer à nouveau sur le lien dans l'e-mail de bienvenue ; si le problème persiste, veuillez contacter support@puls-solutions.com",
      "invalid": "Ce lien n'est plus valide, cliquez plutôt ci-dessous :",
      "noPassword": "Vous devez choisir un mot de passe.",
      "passwordsMustMatch": "Les mots de passe doivent correspondre.",
      "sessionError": "Nous avons rencontré un problème !",
      "SSONotFound": "Il n'y a pas de configuration SSO pour l'organisation.",
      "unexpected": "Un problème inattendu s'est produit"
    },
    "header": "Paramètres",
    "introText": "Vérifiez que votre profil utilisateur est correct.",
    "introTextVolontary":
      "Vous pouvez laisser des champs vides si vous le souhaitez.",
    "login": "Continuer"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "Annuler",
      "done": "Ok",
      "send": "Envoyer de façon anonyme"
    },
    "description":
      "Nous vous donnons la possibilité d’envoyer de manière anonyme un rapport sur un comportement illégal ou un délit dont vous avez été témoin au sein de {organizationName}.",
    "detailedDescription":
      "Voici comment ça marche. Vous saisissez votre rapport dans la zone de texte ci-dessous. Lorsque vous envoyez le rapport, un courriel est envoyé à un destinataire prédéfini de votre organisation, chargé de prendre des mesures. Il est impossible pour le destinataire de voir que le message a été envoyé par vous, mais seulement qu’il provient de la plateforme Puls.  Si vous souhaitez être contacté, veillez à inclure des informations à ce sujet dans votre message. Si vous souhaitez rester anonyme, vous pouvez utiliser une adresse électronique (temporaire) ou une carte SIM  jetable pour téléphone portable.",
    "doneText":
      "Merci d’avoir soumis votre rapport. Nous le prendrons au sérieux. Si vous avez fourni des informations de contact, nous vous répondrons dès que possible !",
    "linkTitle": "En savoir plus ici",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description":
        "Veuillez nous excuser. La fonction de lanceur d’alerte n’est pas activée par votre organisation.",
      "title": "Non disponible"
    },
    "placeholder":
      "Saisissez votre rapport ici. Soyez aussi précis que possible et faites-nous savoir si et comment vous souhaitez être contacté.",
    "title": "Envoyer un rapport anonyme"
  }
}
