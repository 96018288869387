export default {
  "completePassword": {
    "button": {
      "submit": "Pošalji"
    },
    "header": "Izaberi novu lozinku",
    "placeholder": {
      "confirmPassword": "Potvrdi lozinku",
      "password": "Lozinka"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "Svi rezultati",
      "myActions": "MOJE RADNJE",
      "ourCompanyResults": "NAŠI REZULTATI"
    },
    "card": {
      "body":
        "Ovde možeš da pronađeš lične radnje koje si izabrao/la da uradiš. Takođe možeš da vidiš svoj rezultat i kakav je on u poređenju sa celokupnom organizacijom.",
      "title": "Radnje i rezultati"
    },
    "greetings": {
      "day": "Zdravo, {username}!",
      "evening": "Dobro veče, {username}!",
      "morning": "Dobro jutro, {username}!",
      "night": "Laku noć, {username}!"
    },
    "hello": "Zdravo {username}!",
    "integrations": {
      "title": "Integracije"
    },
    "introHeadline": "{productTitle} u {organizationName}",
    "settings": {
      "ar": "Arapski",
      "body": "Ovde možeš da menjaš postavke svog naloga.",
      "da": "Danski",
      "de": "Nemački",
      "en": "Engleski",
      "false": "Norveški",
      "fr": "Francuski",
      "logout": {
        "button": {
          "title": "Odjavi se"
        },
        "confirmation": {
          "areyousure":
            "Ako se odjaviš, moraćeš da zahtevaš jedan novi jednokratni kod za ponovno prijavljivanje.",
          "cancel": "Otkaži",
          "false": "Otkaži",
          "no": "Otkaži",
          "title": "Da li želiš da se odjaviš?",
          "true": "Odjava",
          "yes": "Odjava"
        },
        "loggedin": "Prijavljen/a si kao {username}."
      },
      "no": "Norveški",
      "sv": "Švedski",
      "title": "Postavke"
    }
  },
  "error": {
    "CodeMismatchException":
      "Jednokratni kod koji si uneo/la je nevažeći. Proveri ga i pokušaj ponovo.",
    "couldNotLoadResults":
      "Nije bilo moguće učitati rezultate, pokušaj kasnije.",
    "couldNotLoadSurveys": "Nije bilo moguće učitati ankete, pokušaj kasnije.",
    "emailAtLeast3Letter": "Adresa e-pošte mora da sadrži najmanje 3 slova",
    "emptyEmail": "Unesi svoju adresu e-pošte",
    "emptyOrganization": "Unesi ime svoje organizacije",
    "emptyPassword": "Unesi lozinku",
    "ExpiredCodeException":
      "Jednokratni kod koji si uneo/la je previše star i ne može se koristiti iz bezbednosnih razloga.",
    "InvalidParameterException": "Nedostaje obavezan parametar Sesija.",
    "InvalidPasswordException": "Izaberi sigurniju lozinku.",
    "LimitExceededException":
      "Previše neuspelih pokušaja. Moraš malo da sačekaš pre ponovnog pokušaja.",
    "NotAuthorizedException":
      "Trenutno nije moguće prijavti se pomoću tvog korisničkog naloga.",
    "notMatchPassword": "Lozinke se ne poklapaju!",
    "OrganizationNotFoundException":
      "Nismo uspeli da pronađemo organizaciju pod tim imenom, pokušaj ponovo.",
    "UserNotFoundException":
      "Nismo uspeli da pronađemo korisnički nalog koji je identifikovan kao {username}."
  },
  "forgotten": {
    "backToLogin": "Otkaži",
    "helptext":
      "Ovde možeš da resetuješ lozinku. Primićeš e-poruku na gorenavedenu adresu e-pošte sa kodom koji ćeš uneti u sledećem koraku.",
    "helptext-quickstart":
      "Upravo smo poslali privremeni kod na {email}. Unesi ga ispod i izaberi lozinku.",
    "helptext2": "Izaberi sigurnu lozinku.",
    "input": {
      "code": "Kod pronađen u e-pošti",
      "organization": "Organizacija",
      "password": "Nova lozinka",
      "username": "Adresa e-pošte"
    },
    "requestcode": {
      "submit": "Nastavi"
    },
    "setNewPassword": {
      "submit": "Počni!"
    },
    "wait": "Sačekajte..."
  },
  "general": {
    "defaultProductTitle": "Puls⁺"
  },
  "help": {
    "card": {
      "body":
        "Da li bilo kako možemo da ti pomognemo? Samo klikni na dugme ispod za odlazak u centar za pomoć.",
      "title": "Pomoć i podrška"
    },
    "description": "Ne brini, tu smo za podršku.",
    "title": "POMOĆ"
  },
  "ie": {
    "detailedText":
      "Platforma Plus može da se koristi sa Internet Explorer-om, ali to je stari veb pregledač koji nije usaglašen sa savremenim standardima na vebu. Zbog toga ti preporučujemo da koristiš neki od pregledača ispod. Takođe možeš da koristiš svoj mobilni telefon.",
    "detailedTitle": "O Internet Explorer-u",
    "readMore": "Pročitaj više",
    "warning":
      "Koristiš Internet Explorer. Preporučujemo da se prebaciš na drugi veb pregledač radi najboljeg iskustva."
  },
  "integrations": {
    "addIntegrationSuccess": "Integracija je uspešno dodata!",
    "allIntegrations": "Sve integracije",
    "apps": {
      "sftp": {
        "connectionInfo": "Otpremiće se u {dir} na SFTP serveru.",
        "connectionTitle": "SFTP veza",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "Povezano sa {teamName} kanal {channel}",
        "connectionTitle": "Pasivno vreme za {appDetail}",
        "title": "Pasivno vreme"
      }
    },
    "back": "Nazad",
    "buttons": {
      "deleteIntegration": {
        "help": "Ovo će ukloniti {type} iz Puls-a."
      }
    },
    "connectedSince": "Povezano od {date}",
    "empty": {
      "description":
        "Dodavanjem integracije možeš da povežeš Puls sa vlastitom uslugom. Izaberi dugme ispod za povezivanje sa uslugom."
    },
    "errors": {
      "invalidIncomingIntegration":
        "Nevažeći dolazeći podaci za integraciju! Nastavak nije moguć."
    },
    "events": {
      "surveyCompleted": {
        "description":
          "Kada je anketa završena, rezultati za {groupTitle} automatski se šalju u integraciju.",
        "title": "Anketa je završena"
      },
      "title": "Izaberi događaj kada integraciju treba kontaktirati"
    },
    "groups": {
      "title": "Izaberi grupu za povezivanje sa {appName}"
    },
    "integrationTo": "Integracija u {appName}",
    "nothingSelected": "Izaberite...",
    "setupIntegration": {
      "saveButtonTitle": "Sačuvaj",
      "title": "Postavi integraciju za {appName}"
    },
    "title": "Integracije"
  },
  "login": {
    "button": {
      "forgotPassword": "Zaboravljena lozinka",
      "submit": "Prijava"
    },
    "captchaLegal": {
      "disclaimer":
        "Ovaj sajt štiti reCAPTCHA i važe Google {toc} i {privacyPolicy}.",
      "policy": "Politika privatnosti",
      "toc": "Uslovi korišćenja"
    },
    "header": "Prijava",
    "placeholder": {
      "email": "Adresa e-pošte",
      "organization": "Organizacija",
      "password": "Lozinka"
    }
  },
  "navbar": {
    "welcome": "Dobro došli"
  },
  "results": {
    "participants": "{participants} učesnika",
    "surveys": {
      "completed": {
        "latest": "Poslednja završena anketa",
        "title": "Rezultati završenih anketa"
      },
      "emptyResult": "Uskoro ćeš moći da vidiš rezultate ovde!",
      "published": {
        "title": "Proveri rezultate"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info":
          "Ovo je neophodno za sakupljanje statistike na osnovu različitih starosnih grupa.",
        "label": "Datum rođenja"
      },
      "birth_date": {
        "info":
          "Ovo je neophodno za sakupljanje statistike na osnovu različitih starosnih grupa.",
        "label": "Datum rođenja"
      },
      "custom:employed_since": {
        "info":
          "Datum početka tvog zaposlenja Ne mora da bude baš taj datum. Preskoči izbor ako nisi zaposlen/a.",
        "label": "Zaposlen/a od"
      },
      "employment_date": {
        "info":
          "Datum početka tvog zaposlenja Ne mora da bude baš taj datum. Preskoči izbor ako nisi zaposlen/a.",
        "label": "Zaposlen/a od"
      },
      "family_name": {
        "label": "Prezime"
      },
      "gender": {
        "few": "Drugo",
        "one": "Drugo",
        "other": "Drugo",
        "zero": "Drugo",
        "female": "Ženski",
        "info":
          "Postavljamo pitanja o rodu kako bi to moglo da se obuhvati statistikom. Ništa ne moraš da izabereš ako ne želiš.",
        "label": "Izaberi rod:",
        "male": "Muški",
        "wontsay": "Ne želim da kažem"
      },
      "given_name": {
        "label": "Ime"
      },
      "locale": {
        "ar": "Arapski",
        "da": "Danski",
        "de": "Nemački",
        "dk": "Danski",
        "en": "Engleski",
        "es": "Španski",
        "false": "Norveški",
        "fr": "Francuski",
        "info":
          "Izaberi jezik koji želiš da koristiš. Ovo se takođe kasnije može promeniti.",
        "label": "Izaberi jezik:",
        "ms": "Malajski",
        "no": "Norveški",
        "sv": "Švedski"
      }
    },
    "button": {
      "submit": "Sačuvaj",
      "tryAgain": "Pokušaj ponovo"
    },
    "error": {
      "badPassword": "Izaberi sigurniju lozinku.",
      "clickLinkAgain":
        "Još jednom klikni na vezu u e-poruci dobrodošlice. Ako se problem i dalje nastavi, kontaktiraj support@puls-solutions.com",
      "invalid": "Ta veza više ne važi, umesto toga klikni ispod:",
      "noPassword": "Moraš da izabereš lozinku.",
      "passwordsMustMatch": "Lozinke moraju da se poklapaju.",
      "sessionError": "Naišli smo na problem!",
      "SSONotFound": "Nema SSO konfiguracije za organizaciju.",
      "unexpected": "Došlo je do neočekivanog problema"
    },
    "header": "Prvi koraci u Puls-u!",
    "introText":
      "Zdravo{name}! Dobio/la si poziv za Puls, platformu za angažovanje saradnika. Aktiviraj svoj nalog tako što ćeš popuniti obrazac ispod. ",
    "introTextVolontary": "Obavezno je popuniti samo lozinku.",
    "login": "Nastavi",
    "placeholder": {
      "confirmPassword": "Potvrdi lozinku",
      "newPassword": "Izaberi lozinku"
    }
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "Nastavi"
      },
      "requestCode": {
        "title": "Dobavi jednokratni kod"
      },
      "submitCode": {
        "title": "Prijava"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "Mi samo želimo da se prvo uverimo da si ti prava osoba! Kada nastaviš, biće ti poslat jednokratni kod. Upotrebi ga na sledećem koraku za prijavljivanje na {productTitle} u organizaciji {organizationName}.",
        "help":
          "Nedavno si zahtevao/la kod. Obavezno unesi kod u prozor iz koga si ga zahtevao/la. Ako nastaviš ovde, biće ti poslat novi kod."
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "jednokratni kod"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "e-pošta",
          "SMS": "tekstualna poruka"
        },
        "description":
          "Poslat ti je jednokratni kod putem {deliveryMedium}. Ne zatvaraj ovaj prozor i vrati se da ispod uneseš kod:",
        "title": "Dobro došli u {productTitle}!"
      },
      "help": {
        "noCodeDescriptionEmail":
          "Kod ti je poslat putem e-pošte na adresu koja uključuje '{destination}'. Potrebno je minut ili dva da kod stigne. Ako ne možeš da ga nađeš, proveri fasciklu neželjene pošte. Ne zatvaraj ovaj prozor i unesi kod iznad.",
        "noCodeDescriptionSMS":
          "Kod je poslat kao tekstualna poruka na tvoj mobilni telefon i broj telefona koji uključuje '{destination}'. Potrebno je minut ili dva da kod stigne. Ako ne prepoznaješ broj telefona, kontaktiraj svog administratora.",
        "noCodeLinkTitle": "Zar nisi dobio/la kod?",
        "noCodeStartOverLinkTitle": "Počni ispočetka"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "adresa e-pošte"
        }
      },
      "header": {
        "description": "Unesi svoju adresu e-pošte u {organizationName}:"
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "naziv organizacije"
        }
      },
      "header": {
        "description":
          "Počni tako što ćeš uneti naziv organizacije u kojoj radiš.",
        "invalid": {
          "spaces": "Nemoj da koristiš razmake.",
          "specialchars": "Koristi samo slova.",
          "uppercase": "Koristi samo mala slova."
        }
      }
    }
  },
  "surveys": {
    "description": "Trenutno su aktuelne sledeće ankete:",
    "descriptionOneSurveyOnly": "Izaberi anketu ispod i počni!",
    "descriptionOneSurveyOnlyUserDone":
      "Anketa je još uvek aktuelna. Izaberi je i pogledaj koje si radnje izabrao/la.",
    "ended": "završeno",
    "youreDone": "Završio/la si"
  },
  "surveysToFillIn": {
    "description": "Trenutno su aktuelne sledeće ankete:",
    "descriptionOneSurveyOnly": "Izaberi anketu ispod i počni!",
    "descriptionOneSurveyOnlyUserDone":
      "Anketa je još uvek aktuelna. Izaberi je i pogledaj koje si radnje izabrao/la.",
    "emptyResult":
      "U ovom trenutku nema ankete koja čeka da je popuniš. Možeš da baciš pogled na svoje radnje/komentare ili rezultate ovde ispod.",
    "ended": "završeno",
    "endsIn": "završava se",
    "retry": "Pokušaj ponovo",
    "title":
      "Trenutna anketa|Imaš anketu za popunjavanje|Čeka te {count} anketa",
    "youreDone": "Završio/la si"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info":
          "Ovo je neophodno za sakupljanje statistike na osnovu različitih starosnih grupa.",
        "label": "Datum rođenja"
      },
      "birth_date": {
        "info":
          "Ovo je neophodno za sakupljanje statistike na osnovu različitih starosnih grupa.",
        "label": "Datum rođenja"
      },
      "custom:employed_since": {
        "info":
          "Datum kada si se zaposlio/la. Ne mora da bude tačno taj datum. Preskoči izbor ako nisi zaposlen/a.",
        "label": "Zaposlen/a od"
      },
      "employment_date": {
        "info":
          "Datum kada si se zaposlio/la. Ne mora da bude tačno taj datum. Preskoči izbor ako nisi zaposlen/a.",
        "label": "Zaposlen/a od"
      },
      "family_name": {
        "label": "Prezime"
      },
      "gender": {
        "few": "Drugo",
        "one": "Drugo",
        "other": "Drugo",
        "zero": "Drugo",
        "female": "Ženski",
        "info":
          "Pitamo za rod kako bi bio obuhvaćen statistikom. Ako ne želiš, ne moraš ništa da izabereš.",
        "label": "Izaberi rod:",
        "male": "Muški",
        "wontsay": "Ne želim da kažem"
      },
      "given_name": {
        "label": "Ime"
      },
      "locale": {
        "ar": "Arapski",
        "da": "Danski",
        "de": "Nemački",
        "en": "Engleski",
        "es": "Španski",
        "false": "Norveški",
        "fi": "Finski",
        "fr": "Francuski",
        "info":
          "Izaberi jezik koji želiš da koristiš. Ovo se takođe može kasnije promeniti.",
        "it": "Italijanski",
        "label": "Izaberi jezik:",
        "ms": "Malajski",
        "nl": "Holandski",
        "no": "Norveški",
        "pl": "Poljski",
        "sv": "Švedski"
      }
    },
    "button": {
      "cancel": "Otkaži",
      "skip": "Idi nazad",
      "submit": "Sačuvaj",
      "tryAgain": "Pokušaj ponovo"
    },
    "error": {
      "badPassword": "Izaberi sigurniju lozinku.",
      "clickLinkAgain":
        "Klikni još jednom na vezu u e-pošti dobrodošlice. Ako se problem nastavi, kontaktiraj support@puls-solutions.com",
      "invalid": "Veza više ne važi, umesto toga klikni ispod:",
      "noPassword": "Moraš da izabereš lozinku.",
      "passwordsMustMatch": "Lozinke moraju da se poklapaju.",
      "sessionError": "Naišli smo na problem!",
      "SSONotFound": "Nema SSO konfiguracije za organizaciju.",
      "unexpected": "Došlo je do neočekivanog problema"
    },
    "header": "Korisničke postavke",
    "introText": "Proveri da li je tvoj korisnički profil tačan.",
    "introTextNoAttributes": "Ovde trenutno nema postavki za menjanje.",
    "introTextVolontary": "Informacije ispod popunjavaju se dobrovoljno.",
    "login": "Nastavi"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "Otkaži",
      "done": "U redu",
      "send": "Pošalji anonimno"
    },
    "description":
      "Mi ti dajemo mogućnost anonimnog prijavljivanja nezakonitog ponašanja ili prekršaja čiji si svedok na{organizationName}.",
    "detailedDescription":
      "Evo kako to funkcioniše. Uneseš prijavu u polje za tekst ispod. Kada pošalješ prijavu, e-pošta se šalje unapred definisanom primaocu u tvojoj organizaciji koji ima odgovornost da preduzme mere. Nemoguće je da primalac vidi da poruka dolazi od tebe, vidi se samo da je došla sa Puls platforme.  Ako želiš da te kontaktiramo, obavezno uključi informacije o tome u svojoj poruci. Ako želiš da ostaneš anoniman/na, možeš da koristiš jednokratnu (privremenu) adresu e-pošte ili SIM karticu za mobilni telefon.",
    "doneText":
      "Hvala što si podneo/la prijavu. Ozbiljno ćemo joj pristupiti. Ako si ostavio/la kontakt informacije, kontaktiraćemo te što pre!",
    "linkTitle": "Pročitaj više ovde",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description":
        "Izvinjenje. Tvoja organizacija nije omogućila funkciju uzbunjivača.",
      "title": "Nije dostupno"
    },
    "placeholder":
      "Unesi svoju prijavu ovde. Što konkretnije opiši situaciju i obavesti nas da li želiš da te kontaktiramo i na koji način.",
    "title": "Pošalji anonimni izveštaj"
  }
}
